import { useMutation } from '@tanstack/vue-query'

import { request } from '@/server/core/request'

export const MUTATE_KEY = 'MUTATE_INSURANCE'

interface PostInsurancePayload {
    lineId: string
    creatorName: string
    urlId: string
    userId: string
    fileType: string
    insurances: any[]
}

async function postInsurance(data: PostInsurancePayload) {
    return new Promise((resolve, reject) => {
        request('POST', `/ct_api/insurance/pdfImport`, { data })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export default function mutationInsurancePDF() {
    return useMutation({
        mutationKey: [MUTATE_KEY],
        mutationFn: (data: PostInsurancePayload) => postInsurance(data)
    })
}
