<script setup lang="ts">
import {
    useIsFetching,
    useIsMutating,
    useQueryClient
} from '@tanstack/vue-query'
import { watchImmediate } from '@vueuse/core'
import { computed } from 'vue'

import { MUTATE_KEY } from '@/server/api/BOT/mutationInsurancePDF'
import { MUTATE_KEY as MUTATE_INSURANCE_KEY } from '@/server/api/mutationInsurance'
import { MUTATE_KEY as MUTATE_INSURANCE_PDF_FILE_KEY } from '@/server/api/mutationInsurancePdfFile'
import { useAppStore } from '@/store/app'

const appStore = useAppStore()

const queryClient = useQueryClient()
const isMutating = useIsMutating()
const isFetching = useIsFetching() || queryClient.isFetching()
const isMutateInsurancePDF = useIsMutating({
    mutationKey: [MUTATE_KEY]
})
const isMutateInsurance = useIsMutating({
    mutationKey: [MUTATE_INSURANCE_KEY]
})
const isMutateInsurancePdfFile = useIsMutating({
    mutationKey: [MUTATE_INSURANCE_PDF_FILE_KEY]
})

const isLoading = computed(() => {
    return (
        (isFetching.value || isMutating.value > 0) &&
        !isMutateInsurance.value &&
        !isMutateInsurancePDF.value &&
        !isMutateInsurancePdfFile.value
    )
})

const spots = [{ delay: 0 }, { delay: 0.2 }, { delay: 0.4 }]

const switchBodyScroll = (val: boolean) => {
    const body = document.querySelector('body')
    if (val) {
        body?.classList.add('not-scroll')
    } else {
        body?.classList.remove('not-scroll')
    }
}

watchImmediate(
    () => isLoading,
    (val) => switchBodyScroll(!!val)
)
watchImmediate(
    () => appStore.isShowLoading,
    (val) => switchBodyScroll(val)
)
</script>

<template>
    <div
        v-if="appStore.isShowLoading || isLoading"
        class="fixed top-0 left-0 h-screen w-screen center flex-col bg-white-secondary z-50"
    >
        <div class="w-[72px] h-[50px] flex items-center justify-between">
            <span
                v-for="(spot, idx) in spots"
                :key="idx"
                class="loader"
                :style="{ '--delay': `${spot.delay}s` }"
            />
        </div>
        <div class="text-xs text-grey-secondary">正在加載中，請耐心等候</div>
    </div>
</template>

<style scoped lang="scss">
.loader {
    @apply w-[13px] h-[13px] rounded-full bg-secondary;

    --delay: 0s;
    animation: flash 1.5s var(--delay) infinite;
}

@keyframes flash {
    0% {
        transform: translateY(0%);
    }

    20% {
        transform: translateY(-75%);
    }

    50% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(0%);
    }
}
</style>
