import { useMutation } from '@tanstack/vue-query'

import { request } from '@/server/core/request'

export const MUTATE_KEY = 'MUTATE_INSURANCE_PDF_FILE'

async function postInsurancePdfFile(data: FormData) {
    return new Promise((resolve, reject) => {
        request('POST', `/ct_api/insurance/pdfUpload`, {
            data,
            contentType: 'multipart/form-data'
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export default function mutationInsurancePdfFile() {
    return useMutation({
        mutationKey: [MUTATE_KEY],
        mutationFn: (data: FormData) => postInsurancePdfFile(data)
    })
}
