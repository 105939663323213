import * as Sentry from '@sentry/vue'
import axios from 'axios'
import dayjs from 'dayjs'

import ignoreErrors from '@/assets/setting/sentry/ignoreErrors.ts'
import liffInit from '@/plugins/liff'
import { useAuthStore } from '@/store/auth.ts'

const clientId = 'ctw'
const TIMEOUT = 60 * 1000

type Method = 'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH'

interface RequestOptions {
    data?: any
    contentType?: string
    baseURL?: string
}

const api = axios.create()
api.interceptors.request.use(
    (request) => {
        return request
    },
    (error) => {
        return Promise.reject(error)
    }
)
api.interceptors.response.use(
    (respond) => {
        return respond
    },
    (error) => {
        return Promise.reject(error)
    }
)

export const request = async (
    method: Method,
    url: string,
    options: RequestOptions = {}
) => {
    const authStore = useAuthStore()
    const tokenExpAt = authStore.tokenExpireAt || dayjs().unix()
    if (dayjs().diff(dayjs.unix(tokenExpAt)) > 0) {
        sessionStorage.clear()
        await liffInit()
    }
    const { data, contentType, baseURL } = options
    const headers = {
        Authorization: `${authStore.idToken}`,
        'Client-ID': clientId,
        'Content-Type': contentType || 'application/json'
    }

    return await api({
        url,
        method,
        baseURL,
        data,
        headers,
        timeout: TIMEOUT
    })
        .then((result) => {
            return result
        })
        .catch((error) => {
            Object.keys(ignoreErrors).some((key) => {
                if (
                    !error.config.url.includes(key) &&
                    !ignoreErrors[key]?.includes(error.message)
                ) {
                    let serializedData: any
                    if (data instanceof FormData) {
                        serializedData = {}
                        for (const [key, value] of data.entries()) {
                            if (value instanceof File) continue
                            serializedData[key] = value
                        }
                    } else {
                        serializedData = data
                    }
                    Sentry.captureException(error, {
                        extra: {
                            response_body: serializedData
                        }
                    })
                    return true
                }
            })
            throw error
        })
}
